<template>
  <div class="m-container" :class="[padding, allowHoverEffect ? 'hoverable' : '']">
    <img v-if="isSelected" class="m-check-mark" alt="checkmark" src="../assets/checked.svg" />
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Container',
  props: {
    isSelected: {
      type: Boolean,
      default: false,
    },
    allowHoverEffect: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: String,
      default: 'p-5',
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/colors";

.m-container {
  position: relative;

  background: $container-bg-color;
  border: 1.2px solid $container-border-color;
  box-sizing: border-box;
  box-shadow: 0 0 26.4158px rgba(0, 0, 0, 0.08);
  border-radius: 30px;

  cursor: pointer;
}

.m-container.hoverable:hover {
  background-color: $tertiary-color;
  border: 1.3px solid $container-hover-bg-color;
  border-radius: 10px;

  transform: scale(1.05);
  transform-origin: center;
}

.m-check-mark {
  width: 70px;
  height:70px;

  position: absolute;
  right: -25px;
  top: -25px;
}
</style>
