<template>
  <container
    padding="pb-5"
    :is-selected="selected"
    :allow-hover-effect="!selected"
    :class="selected && !expanded ? 'top-portion' : ''">
    <div class="px-5 pt-5" :class="selected ? 'top-portion' : ''">
      <h2 class="mt-0">{{ title }}</h2>
      <h3 v-if="subTitle != null && subTitle.length > 0" class="f-900">{{ subTitle }}</h3>
      <cost-totals :total-cost="totalCost" :pepm-cost="pepmCost" class="mt-4" />
      <hr v-if="expanded">
    </div>

    <div v-if="expanded" class="unbalanced-px pt-3">
      <cost-line-items>
        <cost-line-item
          v-for="(item, index) in items"
          :key="index"
          :right="item.right"
          :middle="item.middle"
          :left="item.left"
          :sub-items="item.subItems" />
      </cost-line-items>
    </div>
  </container>
</template>

<script>
import Container from './Container.vue';
import CostTotals from './CostTotals.vue';
import CostLineItem from './CostLineItem.vue';
import CostLineItems from './CostLineItems.vue';

export default {
  name: 'PricingBreakdown',
  components: {
    CostLineItems,
    CostLineItem,
    CostTotals,
    Container,
  },
  props: {
    selected: {
      type: Boolean,
      required: true,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: false,
    },
    items: Array,
    totalCost: Number,
    pepmCost: Number,
  },
  computed: {
    expanded() {
      return this.selected && this.items != null && this.items.length > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/_colors.scss";

.top-portion {
  background-color: $tertiary-color;
  border-radius: 30px;
}

.unbalanced-px {
  padding-left: 10px;
  padding-right: 40px;
}

hr {
  height: 2px !important;
  color: $divider-color;
}
</style>
