<template>
  <b-container class="pb-5" fluid>
    <b-row>
      <b-col xl="6" lg="12">
        <container class="h-100">
          <h4 class="text-left mb-0">Dollars returned</h4>
          <h4 class="text-left">
            on <span class="f-900">investment</span>
            <more-info>
              <h6 class="color-secondary-font">
                Estimates of ROI range from
                <b>{{ low.multiplier | currency }}</b> to
                <b>{{ high.multiplier | currency }}</b>
                for every dollar invested.
                <br><br>
                Lokkerbol et al. (2014); Chisholm et al. (2016)
              </h6>
            </more-info>
          </h4>

          <div class="h-75 w-100 d-inline-flex align-items-center">
            <b-table-simple class="mt-4 w-100" borderless responsive>
              <b-tbody>
                <b-tr>
                  <b-td></b-td>
                  <b-td class="text-right"><label class="shrink">Gross</label></b-td>
                  <b-td class="text-right"><label class="shrink">Net</label></b-td>
                </b-tr>
                <b-tr>
                  <b-td class="text-left"><label>Low</label></b-td>
                  <b-td class="text-right">
                    <h4 class="currency-neutral f-900">{{ low.gross | currencyRounded }}</h4>
                  </b-td>
                  <b-td class="text-right">
                    <h4 class="currency-positive f-900">{{ low.net | currencyRounded }}</h4>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td class="text-left"><label>High</label></b-td>
                  <b-td class="text-right">
                    <h4 class="currency-neutral f-900">{{ high.gross | currencyRounded }}</h4>
                  </b-td>
                  <b-td class="text-right">
                    <h4 class="currency-positive f-900">{{ high.net | currencyRounded }}</h4>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
        </container>
      </b-col>
      <b-col xl="6" lg="12" class="mt-xl-0 mt-4">
        <container class="h-100">
          <h4 class="text-left mb-0">Impacts of reduced</h4>
          <h4 class="text-left">
            <span class="f-900">absenteeism</span>
            <more-info>
              <h6 class="color-secondary-font">
                There is a significant <b>negative</b> correlation between the number of meetings
                with a peer counselor/coach and <b>absenteeism</b> defined as being absent
                from work due to illness.
                <br><br>
                Sagui-Hensen et al. (2021)
              </h6>
            </more-info>
          </h4>

          <img
            src="../assets/reduced-absenteeism-impact.svg"
            alt="Absenteeism Graph"
            class="mt-4 graphic" />
        </container>
      </b-col>
    </b-row>
    <b-row class="mt-4 pb-4">
      <b-col>
        <container>
          <h4 class="text-left">
            Impacts on
            <span class="f-900">well-being</span>
            <more-info>
              <h6 class="color-secondary-font">
                There is a significant <b>positive</b> correlation between
                the number of meetings with a peer counselor/coach and <b>well-being</b> as
                measured with the WHO-5.
                <br><br>
                Sagui-Hensen et al. (2021)
              </h6>
            </more-info>
          </h4>

          <b-row class="mt-4" align-v="center">
            <b-col md="7" sm="12">
              <img
                src="../assets/well-being-impact.svg"
                alt="Well-being Impact Graph"
                class="graphic" />
            </b-col>
            <b-col md="5" sm="12">
              <img
                src="../assets/well-being-data.svg"
                alt="Well-being Impact Data"
                class="graphic" />
            </b-col>
          </b-row>
        </container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Container from './Container.vue';
import MoreInfo from './MoreInfo.vue';

export default {
  name: 'ROI',
  components: { MoreInfo, Container },
  props: {
    low: {
      type: Object,
      required: true,
    },
    high: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/colors";

td {
  padding: 0 0 15px 0 !important;
}

label {
  font-weight: 600;

  color: $table-label-color !important;
}

.shrink {
  font-weight: 500;
  font-size: 85%;
}

.graphic {
  width: 100%;
}
</style>
