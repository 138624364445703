<template>
  <p>Page not found</p>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>

<style scoped>

</style>
