<template>
  <div class="d-inline-flex justify-content-center p-1">
    <img src="../assets/more-info.svg" alt="More Info" class="more-info" ref="icon" />
    <b-popover
      :target="() => $refs['icon']"
      triggers="hover"
      placement="top"
      custom-class="custom-popover-wrapper">
      <slot></slot>
    </b-popover>
  </div>
</template>

<script>
export default {
  name: 'MoreInfo',
};
</script>

<style scoped>
.more-info {
  height: 18px;
  width: 18px;
}

.custom-popover-wrapper {
  background-color: #EFF0F7 !important;
  border-radius: 16px;
  border: none;
}
</style>
