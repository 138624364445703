import Vue from 'vue';
import BootstrapVue, { IconsPlugin } from 'bootstrap-vue';
import axios from 'axios';
import NotFound from './components/NotFound.vue';
import Calculator from './components/Calculator.vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './assets/styles/_common.scss';

const routes = {
  '/': NotFound,
  '/calc': Calculator,
};

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.prototype.$http = axios.create();

Vue.filter('currency', (value) => {
  if (typeof value !== 'number') {
    return value;
  }

  const wholeNumberFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });

  const fractionalNumberFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  if (value % 1 === 0) {
    return wholeNumberFormatter.format(value);
  }

  return fractionalNumberFormatter.format(value);
});

Vue.filter('currencyRounded', (value) => {
  if (typeof value !== 'number') {
    return value;
  }

  const roundedValue = Math.ceil(value);

  const wholeNumberFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  });

  return wholeNumberFormatter.format(roundedValue);
});

Vue.filter('pretty', (value) => {
  if (typeof value !== 'number') {
    return value;
  }

  const formatter = new Intl.NumberFormat('en-US');

  return formatter.format(value);
});

// eslint-disable-next-line no-new
new Vue({
  el: '#app',
  data: {
    currentRoute: window.location.pathname,
  },
  computed: {
    ViewComponent() {
      return routes[this.currentRoute] || NotFound;
    },
  },
  render(h) { return h(this.ViewComponent); },
});
