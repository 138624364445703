<template>
  <b-overlay :show="config == null" variant="transparent" blur="3px" class="color-secondary">
    <b-container class="calculator-container" fluid>
      <img src="../assets/full-logo.svg" alt="Mindfuli Logo" class="logo-fixed d-none d-md-block" />
      <div class="abstract-background" />
      <b-row class="calculator-row">
        <b-col
          class="calculator-output floated-content pb-0"
          md="5"
          sm="12">
          <div class="content">
            <pricing-breakdown
              v-for="option in options" :key="option.key"
              :selected="option.selected"
              :title="option.title"
              :sub-title="option.subTitle"
              :items="option.selected ? lineItems : null"
              :total-cost="option.totalCost"
              :pepm-cost="option.pepmCost"
              class="my-5"
              @click.native="option.onClick()" />
          </div>
        </b-col>
        <b-col
          class="calculator-input"
          :class="selectedSegmentedControlOption === 'calc' ? 'floated-content' : ''"
          md="7"
          sm="12">
          <div
            :class="selectedSegmentedControlOption === 'calc' ? 'content-narrow' : 'content'">
            <img
              src="../assets/full-logo.svg"
              alt="Mindfuli Logo"
              class="logo-stacked" />

            <segmented-control
              class="mb-5 m-auto"
              :disabled="!readyToCalculate"
              :options="segmentedControlOptions"
              v-model="selectedSegmentedControlOption" />

            <span v-if="selectedSegmentedControlOption === 'calc'">
              <div class="mb-5">
                <h2>Counseling</h2>
                <h3 v-if="selectedOption === 'clinical'" class="f-900">+ Clinical</h3>
              </div>

              <labeled-input
                prompt="How many employees do you have?"
                placeholder="Number of employees"
                input-type="number"
                v-model="employeeCount" />
              <labeled-input
                v-if="selectedOption === 'clinical'"
                prompt="How many clinical support hours would you like?"
                placeholder="Number of clinical hours"
                input-type="number"
                v-model="clinicalHours" />
              <labeled-range-input
                v-if="this.config !== null"
                prompt="Percentage of utilization"
                :options="Object.keys(this.config.utilization)"
                v-model="utilizationPercentage" />

              <b-row class="mt-5 pb-5">
                <b-col>
                  <b-link
                    href="#"
                    variant="link"
                    class="f-600 text-decoration-none color-primary"
                    :disabled="!readyToCalculate"
                    @click.native="reset">
                    Reset
                  </b-link>
                </b-col>
              </b-row>
            </span>
            <ROI
              v-else-if="selectedSegmentedControlOption === 'roi'"
              :low="roi.low"
              :high="roi.high" />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </b-overlay>
</template>

<script lang="ts">
import PricingBreakdown from './PricingBreakdown.vue';
import LabeledInput from './LabeledInput.vue';
import LabeledRangeInput from './LabeledRangeInput.vue';
import SegmentedControl from './SegmentedControl.vue';
import ROI from './ROI.vue';

export default {
  name: 'Calculator',
  components: {
    ROI,
    SegmentedControl,
    LabeledRangeInput,
    LabeledInput,
    PricingBreakdown,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      config: null,
      selectedOption: 'peer',
      segmentedControlOptions: [
        {
          title: 'Calculator',
          code: 'calc',
        },
        {
          title: 'ROI',
          code: 'roi',
        },
      ],
      selectedSegmentedControlOption: 'calc',

      // Input
      employeeCount: '',
      clinicalHours: '', // This goes straight to output too
      utilizationPercentage: 30,

      // Output
      employeesCovered: 0,
      counselingHours: 0,
      totalCost: 0.0,
      pepmCost: 0.0,
      clinicalCost: 0.0,
      lineItems: null,
      roi: null,
    };
  },
  methods: {
    calculate() {
      if (!this.readyToCalculate) {
        this.totalCost = 0.0;
        this.pepmCost = 0.0;
        this.clinicalCost = 0.0;
        this.lineItems = null;
        return;
      }

      const percentages = Object.keys(this.config.utilization);
      const minPepm = this.config.utilization[percentages[0]].pepm;
      const maxPepm = this.config.utilization[this.utilizationPercentage].pepm;
      this.pepmCost = (minPepm + maxPepm) / 2;

      this.employeesCovered = Math.round(this.employeeCount * (this.utilizationPercentage / 100));
      this.totalCost = this.employeesCovered * this.pepmCost;

      this.counselingHours = this.config.utilization[this.utilizationPercentage].peerHours;

      if (this.clinicalHours === '') {
        this.clinicalHours = (this.counselingHours
          * this.config.utilization[this.utilizationPercentage].clinicalHoursDefaultMultiplier);
      }

      this.clinicalCost = this.clinicalHours * this.config.clinicalHoursPriceMultiplier;

      this.buildLineItems();
      this.buildRoi();
    },
    buildLineItems() {
      const items = [
        {
          left: '24/7',
          middle: 'chat coverage',
          right: 'unlimited',
        },
        {
          left: 'NO.',
          middle: 'employees covered',
          right: this.$options.filters.pretty(this.employeesCovered),
        },
        {
          left: 'EST.',
          middle: 'utilization bracket',
          right: `${this.utilizationPercentage}%`,
          subItems: this.buildSubLineItems(),
        },
        {
          left: 'HRS.',
          middle: 'counseling voice/video',
          right: `${this.$options.filters.pretty(this.counselingHours)} hrs`,
        },
      ];

      if (this.selectedOption === 'clinical') {
        items.push({
          left: 'HRS.',
          middle: 'clinical voice/video',
          right: `${this.$options.filters.pretty(this.clinicalHours)} hrs`,
        });
      }

      this.lineItems = items;
    },
    buildSubLineItems() {
      if (this.config === null) {
        return [];
      }

      const items = [];

      const percentages = Object.keys(this.config.utilization);
      const currentPercentageIndex = percentages.indexOf(this.utilizationPercentage.toString());
      let low = null;
      for (let i = 0; i <= currentPercentageIndex; i += 1) {
        const percentage = percentages[i];
        const price = this.config.utilization[percentage].pepm;
        if (low === null) {
          low = percentage;
        } else if (price !== this.config.utilization[percentages[i - 1]].pepm) {
          // close
          const isRange = low !== percentages[i - 1];
          let title;
          if (isRange) {
            title = `${low}% - ${percentages[i - 1]}%`;
          } else {
            title = `${percentages[i - 1]}%`;
          }

          items.push({
            left: title,
            middle: 'PEPM Cost',
            right: this.$options.filters.currency(this.config.utilization[percentages[i - 1]].pepm),
          });

          low = percentage;
        }
      }

      const isRange = low !== percentages[currentPercentageIndex];
      let title;
      if (isRange) {
        title = `${low}% - ${percentages[currentPercentageIndex]}%`;
      } else {
        title = `${percentages[currentPercentageIndex]}%`;
      }

      items.push({
        left: title,
        middle: 'PEPM Cost',
        right:
          this.$options.filters.currency(this.config.utilization[this.utilizationPercentage].pepm),
      });

      return items;
    },
    buildRoi() {
      const totalCost = this.selectedOption === 'clinical'
        ? this.totalClinicalCost : this.totalCost;
      const lowGross = totalCost * this.config.lowRoiMultiplier;
      const highGross = totalCost * this.config.highRoiMultiplier;

      this.roi = {
        low: {
          gross: lowGross,
          net: lowGross - totalCost,
          multiplier: this.config.lowRoiMultiplier,
        },
        high: {
          gross: highGross,
          net: highGross - totalCost,
          multiplier: this.config.highRoiMultiplier,
        },
      };
    },
    reset() {
      this.employeeCount = '';
      this.clinicalHours = '';
      this.utilizationPercentage = 30;
      this.selectedOption = 'peer';
      this.calculate();
      this.buildRoi();
    },
  },
  watch: {
    employeeCount() {
      this.calculate();
    },
    clinicalHours() {
      this.calculate();
    },
    utilizationPercentage() {
      this.calculate();
    },
    selectedOption() {
      this.calculate();
    },
  },
  computed: {
    readyToCalculate() {
      return this.employeeCount !== '';
    },
    options() {
      const counselingOption = {
        key: 'peer',
        selected: this.selectedOption === 'peer',
        title: 'Counseling',
        subTitle: null,
        totalCost: this.totalCost,
        pepmCost: this.pepmCost,
        onClick: () => {
          this.selectedOption = 'peer';
        },
      };

      const clinicalOption = {
        key: 'clinical',
        selected: this.selectedOption === 'clinical',
        title: 'Counseling',
        subTitle: '+ Clinical',
        totalCost: this.totalClinicalCost,
        pepmCost: (this.employeesCovered > 0
          ? (this.totalClinicalCost / this.employeesCovered) : 0.0),
        onClick: () => {
          this.selectedOption = 'clinical';
        },
      };

      if (this.selectedOption === 'clinical') {
        return [clinicalOption, counselingOption];
      }

      return [counselingOption, clinicalOption];
    },
    totalClinicalCost() {
      return this.totalCost + this.clinicalCost;
    },
  },
  mounted() {
    this.$http.get('/config.json').then((res) => {
      this.config = res.data;
      this.calculate();
    });
  },
};
</script>

<style lang="scss" scoped>
@import "../../node_modules/bootstrap/scss/bootstrap-grid";
@import "../assets/styles/colors";

.floated-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.abstract-background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 27vw;

  background-color: $secondary-background-color;
}

.logo-fixed {
  position: absolute;
  top: 50px;
  left: 50px;
  height: 3vh;
}

.logo-stacked {
  display: block;
  margin-left: auto;
  margin-right: auto;

  width: 300px;
  padding: 50px 0;
}

.calculator-container {
  height: 100vh;

  @include media-breakpoint-down("sm") {
    height: auto;
  }
}

.calculator-container .calculator-row {
  height: 100vh;
}

.calculator-output {
  position: relative;
  height: 100vh;

  padding-bottom: 60px;
}

.calculator-output .content {
  width: calc(80% + 80px);
  padding: 0 80px 0 80px;

  overflow-y: scroll;
  scrollbar-width: 0;
  -ms-overflow-style: none;

  max-height: 100vh;

  @include media-breakpoint-down("sm") {
    width: calc(100vw - 60px);
  }
}

::-webkit-scrollbar {
  display: none;
}

.calculator-input {
  text-align: center;

  height: 100vh;
  overflow-y: scroll;
}

.calculator-input .content-narrow {
  width: 60%;

  max-height: 100vh;

  @include media-breakpoint-down("sm") {
    width: calc(100vw - 60px);
  }
}

.calculator-input .content {
  width: 95%;

  max-height: 100vh;

  @include media-breakpoint-down("sm") {
    width: calc(100vw - 60px);
  }
}

a.disabled {
  opacity: 60% !important;
  cursor: default;
}
</style>
