<template>
  <b-tbody>
    <b-tr v-on:click="isOpened = !isOpened" :class="expandable ? 'pointer' : ''">
      <b-td class="v-align-center w-0">
        <img
          v-if="expandable"
          src="../assets/caret.svg"
          alt="Dropdown arrow"
          class="expander"
          :class="isOpened ? '' : 'closed'" />
      </b-td>
      <b-td class="v-align-center w-0 position-relative pt-3">
        <h5 class="f-900">{{ left }}</h5>
      </b-td>
      <b-td class="v-align-center pt-3">
        <h6 class="f-600">{{ middle }}</h6>
      </b-td>
      <b-td class="v-align-center pt-3">
        <h6 class="text-right color-secondary f-600">{{ right }}</h6>
      </b-td>
    </b-tr>

    <b-tr v-if="expandable && isOpened" v-for="(subItem, index) in subItems" :key="index"> <!-- eslint-disable-line -->
      <b-td class="v-align-center w-0 position-relative pb-0">
      </b-td>
      <b-td class="v-align-center w-0 position-relative pb-0">
      </b-td>
      <b-td class="v-align-center pb-0">
        <p class="f-900 d-inline">{{ subItem.left }}</p>&nbsp;
        <p class="f-500 d-inline">{{ subItem.middle }}</p>
      </b-td>
      <b-td class="v-align-center text-left pb-0">
        <p class="f-600">{{ subItem.right }}</p>
      </b-td>
    </b-tr>
  </b-tbody>
</template>

<script>
export default {
  name: 'CostLineItem',
  props: {
    left: {
      type: String,
      required: true,
    },
    middle: {
      type: String,
      required: true,
    },
    right: {
      type: String,
      required: true,
    },
    subItems: {
      type: [Array, undefined],
    },
  },
  data() {
    return {
      isOpened: false,
    };
  },
  computed: {
    expandable() {
      return this.subItems != null && this.subItems.length > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/common";

@include text {
  margin: 0;
}

.v-align-center {
  vertical-align: middle;
}

.w-0 {
  width: 0;
}

.expander {
  width: 12px;
  height: 12px;
}

.closed {
  transform: rotate(-90deg);
}
</style>
