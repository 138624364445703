<template>
  <b-form-group
    :id="seedId"
    :label="prompt"
    :label-for="`slider-${seedId}`"
    label-class="text-left f-500"
    class="p-2">
    <vue-slider
      v-model="rangeValue"
      :id="`input-${seedId}`"
      dotSize="25"
      :dotStyle="dotStyle"
      :processStyle="processStyle"
      :railStyle="railStyle"
      tooltip="none"
      height="12px"
      :interval="interval"
      :min="min"
      :max="max"
      :adsorb="true"
      :contained="true"
      :marks="marks"
      class="mt-2">
      <template v-slot:step="{ value }">
        <div
          v-if="+value === 50"
          :class="['vue-slider-mark', 'custom-mark']" />
        <div
          v-if="+value === 10"
          :class="['vue-slider-mark', 'custom-mark', 'left-mark']" />
        <div
          v-if="+value === 95"
          :class="['vue-slider-mark', 'custom-mark', 'right-mark']" />
        <div v-else />
      </template>
      <template v-slot:label="{ active, value }">
        <span v-if="+value !== 5 && value % 50 !== 0 && +value !== rangeValue" />
        <p
          v-else-if="+value === rangeValue"
          class="vue-slider-mark-label active-mark">
          {{ value }}%
        </p>
        <p v-else class="vue-slider-mark-label inactive-mark">{{ value }}%</p>
      </template>
    </vue-slider>
  </b-form-group>
</template>

<script>
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';

export default {
  name: 'LabeledRangeInput',
  components: { VueSlider },
  props: {
    prompt: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      seedId: Math.floor((Math.random() * 100000) + 1).toString(),
      dotStyle: {
        border: 'solid 2px #DF6536',
        'box-shadow': 'none',
      },
      railStyle: {
        'background-color': '#EBEDF6',
      },
      processStyle: {
        'background-color': '#F5C3B0',
      },
    };
  },
  computed: {
    marks() {
      const m = {};
      this.options.forEach((o) => {
        m[o] = `${o}%`;
      });

      return m;
    },
    rangeValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
    min() {
      const string = Object.keys(this.marks)[0];
      // eslint-disable-next-line radix
      return parseInt(string);
    },
    max() {
      const stringArray = Object.keys(this.marks);
      // eslint-disable-next-line radix
      return parseInt(stringArray[stringArray.length - 1]);
    },
    interval() {
      const stringArray = Object.keys(this.marks);
      // eslint-disable-next-line radix
      const secondToLast = parseInt(stringArray[stringArray.length - 2]);

      return this.max - secondToLast;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/colors";

.custom-mark {
  width: 8px;
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0.16);
  border-radius: 100px;
}

.left-mark {
  left: 11px;
}

.right-mark {
  left: 2px;
}

.active-mark {
  color: $secondary-color-darker;
  font-weight: 900;
  font-size: 110%;
}

.inactive-mark {
  color: $text-field-placeholder-color;
  font-weight: 550;
  font-size: 80%;
}
</style>
