<template>
  <b-table-simple class="main-table m-0" borderless responsive>
    <slot></slot>
  </b-table-simple>
</template>

<script>
export default {
  name: 'CostLineItems',
};
</script>

<style scoped>

</style>
