<template>
  <div>
    <b-row>
      <b-col class="center-y">
        <h4 class="d-inline-flex">Total Cost</h4>
      </b-col>
      <b-col class="text-right">
        <h3 class="d-inline-flex">{{ totalCost | currencyRounded }}&nbsp;</h3>
        <h5 class="d-inline-flex">/ mo</h5>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col class="center-y">
        <h6 class="d-inline-flex">PEPM Cost</h6>
      </b-col>
      <b-col class="text-right">
        <h5 class="d-inline-flex">{{ pepmCost | currency }}&nbsp;</h5>
        <p class="d-inline-flex f-500">/ mo</p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'CostTotals',
  props: {
    totalCost: Number,
    pepmCost: Number,
  },
};
</script>

<style scoped>

</style>
