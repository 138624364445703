<template>
  <b-form-group
    :id="seedId"
    :label="prompt"
    :label-for="`input-${seedId}`"
    label-class="text-left f-500"
    class="p-2">
    <b-form-input
      :id="`input-${seedId}`"
      class="mt-2 li-custom-input"
      :placeholder="placeholder"
      v-model="inputValue"
      :type="inputType"
      debounce="600"
      trim />
  </b-form-group>
</template>

<script>
export default {
  name: 'LabeledInput',
  props: {
    prompt: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
    },
    value: {
      type: [String, Number],
      required: true,
    },
    inputType: {
      type: String,
      validator(value) {
        return ['text', 'number'].indexOf(value) !== -1;
      },
      default: 'text',
      required: true,
    },
  },
  data() {
    return {
      seedId: Math.floor((Math.random() * 100000) + 1).toString(),
    };
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
  },
};
</script>

<style lang="scss">
@import "../assets/styles/colors";

.li-custom-input {
  background: $text-field-bg-color !important;
  border: 1px solid $text-field-border-color !important;
  box-sizing: border-box !important;
  border-radius: 16px !important;

  padding-left: 20px !important;

  height: 56px !important;
}

.li-custom-input ::placeholder {
  color: $text-field-placeholder-color;
}

.li-custom-input :-ms-input-placeholder {
  color: $text-field-placeholder-color;
}

.li-custom-input ::-ms-input-placeholder {
  color: $text-field-placeholder-color;
}

.li-custom-input:focus {
  box-shadow: 0 0 0 1px $primary-color !important;
}
</style>
