<template>
  <div class="bg-container">
    <button
      v-for="(option, index) in options"
      :key="index"
      class="segmented-button"
      :class="option.code === selectedOption ? 'selected' : ''"
      :style="{ cursor: disabled ? 'default' : 'pointer' }"
      @click="tappedOption(option)">
      {{ option.title }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'SegmentedControl',
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    selectedOption: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
  },
  methods: {
    tappedOption(o) {
      if (this.disabled) {
        return;
      }

      this.selectedOption = o.code;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/colors";

.bg-container {
  background-color: #EFF0F6;
  border-radius: 16px;

  width: max-content;

  padding: 4px;
}

.segmented-button {
  background: none;
  border: none;
  border-radius: 12px;

  font-size: 130%;
  font-weight: 500;

  color: $segmented-control-unselected-color;

  height: 52px;
  padding: 10px 80px;
}

.segmented-button.selected {
  background-color: #FFFFFF;

  font-weight: 550;

  color: $segmented-control-selected-color;
}
</style>
